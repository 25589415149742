<template>
  <div>
    <page-header name="Organizations">
      <template #icon>
        <b-icon
          icon="sitemap"
          type="is-primary"
        />
      </template>

      <template #actions>
        <b-button
          tag="router-link"
          to="/organizations/add"
          type="is-info"
          icon-left="plus"
          icon-pack="fas"
        >
          Add New Organization
        </b-button>
      </template>
    </page-header>

    <section class="section">
      <div class="container">
        <organizations-table />
      </div>
    </section>
  </div>
</template>

<script>
import { PageHeader } from '@/components/Shared';
import { OrganizationsTable } from '@/components/Organizations';

export default {

  name: 'CreateOrganization',

  components: {
    PageHeader,
    OrganizationsTable,
  },

};
</script>

<style lang="css" scoped>
</style>
